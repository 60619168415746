<template>
    <!-- 客户管理 -->
    <div class="digital-customer">
        <a-card>
            <template #title>
                <a-space>
                    <a-button type="primary" @click="showCustomer(null)"
                              v-has="{action:'wxapp_storage_customer_add',plat:0}">
                        <i class="ri-add-line ri-btn"></i>新增客户
                    </a-button>
                    <com-upload-btn
                        v-has="{action:'wxapp_storage_customer_import',plat:0}"
                        btn-text="批量导入"
                        btn-type="default"
                        :customUpload="true"
                        @uploadChange="importCustomer" >
                    </com-upload-btn>
                </a-space>
            </template>
            <template #extra>
                <a-input-search
                    v-model:value="cmState.search.key"
                    placeholder="输入客户编号/名称/联系人/电话/备注查询"
                    enter-button
                    style="width:400px"
                    @search="getCustomer(1,cmState.limit)"
                />
            </template>
            <a-table :pagination="false" rowKey="id" :data-source="cmState.list" :columns="[
                {title:'客户编号',dataIndex:'id'},
                {title:'客户名称',dataIndex:'nickname'},
                {title:'客户等级',dataIndex:'level.level'},
                {title:'联系人',dataIndex:'contact_person'},
                {title:'手机',dataIndex:'mobile'},
                {title:'QQ/微信/email',dataIndex:'contact_number'},
                {title:'应收款余额',dataIndex:'receivables'},
                {title:'送货地址',dataIndex:'address'},
                {title:'销售人员',dataIndex:'salesperson'},
                {title:'备注',dataIndex:'remark'},
                {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: cmState.pks, onChange:(e)=>{cmState.pks = e}  }">
                <template #status="{record}">
                    <a-tooltip :title="record.status === 1 ?'切换状态为禁用':'切换状态为启用'">
                        <a-tag class="pt" :color="record.status ?'#00CC66':'#FF0066'"
                               @click="changeCustomer([record.id],record.status === 1 ?0:1)">
                            {{record.status === 1 ?'启用':'禁用'}}
                        </a-tag>
                    </a-tooltip>
                </template>
                <template #action="{record}">
                    <kd-button title="编辑" icon="ri-edit-line" @click="showCustomer(record)"
                               v-has="{action:'wxapp_storage_customer_add',plat:0}"></kd-button>
                    <kd-button title="删除" icon="ri-delete-bin-2-line" type="danger"
                               v-has="{action:'wxapp_storage_customer_delete',plat:0}"
                               @click="deleteCustomer([record.id])">
                    </kd-button>
                </template>
            </a-table>
            <kd-pager :page-data="cmState" :event="getCustomer">
                <a-space>
                    <a-button @click="changeCustomer(cmState.pks,0)" v-has="{action:'wxapp_storage_customer_status',plat:0}">禁用</a-button>
                    <a-button @click="changeCustomer(cmState.pks,1)" v-has="{action:'wxapp_storage_customer_status',plat:0}">启用</a-button>
                    <a-tooltip title="未勾选要导出的数据时，导出的为导入模板">
                        <a-button @click="exportCustomer" v-has="{action:'wxapp_storage_customer_export',plat:0}">导出（数据/模板）</a-button>
                    </a-tooltip>
                    <a-button @click="deleteCustomer(cmState.pks)" v-has="{action:'wxapp_storage_customer_delete',plat:0}">删除</a-button>
                </a-space>
            </kd-pager>
        </a-card>

        <a-modal v-model:visible="ecState.show" title="添加客户" @ok="saveCustomer" width="1000px">
            <a-form :label-col="{span:6}" :wrapper-col="{span:18}">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <a-form-item label="客户名称" required>
                            <a-input v-model:value="ecState.form.nickname"></a-input>
                        </a-form-item>
                        <a-form-item label="联系人" required>
                            <a-input v-model:value="ecState.form.contact_person"></a-input>
                        </a-form-item>
                        <a-form-item label="电话号码">
                            <a-input v-model:value="ecState.form.mobile"></a-input>
                        </a-form-item>
                        <a-form-item label="客户等级">
                            <a-select v-model:value="ecState.form.level" placeholder="客户等级">
                                <a-select-option :value="0">选择客户等级</a-select-option>
                                <a-select-option v-for="(item,index) in ecState.level" :key="index" :value="item.id">
                                    {{item.value}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="QQ/微信/邮箱">
                            <a-input v-model:value="ecState.form.contact_number"></a-input>
                        </a-form-item>
                        <a-form-item label="销售人员">
                            <a-input v-model:value="ecState.form.salesperson"></a-input>
                        </a-form-item>
                        <a-form-item label="收货地址">
                            <a-input v-model:value="ecState.form.address"></a-input>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-textarea v-model:value="ecState.form.remark" placeholder="备注" :rows="4" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="余额日期">
                            <a-date-picker
                                v-model:value="ecState.form.balance_data"
                                show-time
                                type="date"
                                placeholder="选择日期"
                                style="width: 100%"
                            />
                        </a-form-item>
                        <a-form-item label="开户银行">
                            <a-input v-model:value="ecState.form.bank"></a-input>
                        </a-form-item>
                        <a-form-item label="银行账号">
                            <a-input v-model:value="ecState.form.bank_account"></a-input>
                        </a-form-item>
                        <a-form-item label="纳税人识别号">
                            <a-input v-model:value="ecState.form.tin"></a-input>
                        </a-form-item>
                        <a-form-item label="期初预收款">
                            <a-input v-model:value="ecState.form.prepaid_fee"></a-input>
                        </a-form-item>
                        <a-form-item label="期初应收款">
                            <a-input v-model:value="ecState.form.receivables"></a-input>
                        </a-form-item>
                        <a-form-item label="状态">
                            <a-radio-group v-model:value="ecState.form.status">
                                <a-radio :value="1">启用</a-radio>
                                <a-radio :value="0">禁用</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                </a-row>

			</a-form>
        </a-modal>
    </div>

</template>
<script>
import { useCustomer,useEditCustomer } from '@/models/addons/storage'
import comUploadBtn from '@/components/public/com-upload-btn.vue'

export default {
    components:{
        comUploadBtn
    },
    setup() {
        let { cmState,getCustomer,deleteCustomer,changeCustomer,exportCustomer,importCustomer } = useCustomer()
        getCustomer(1,cmState.limit)
        let { ecState,saveCustomer,showCustomer } = useEditCustomer(cmState,getCustomer )

        return{
            cmState,getCustomer,deleteCustomer,changeCustomer,exportCustomer,importCustomer,
            ecState,saveCustomer,showCustomer
        }
    },
}
</script>
<style scoped lang="scss">
.digital-customer{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>
